<template>
  <main>
    Login Page

    <section>
      <form>
        <h1>{ <span>CODE-SMALL</span> }</h1>
        <h2>Login</h2>
        <label>Username</label>
        <input type="text" v-model="username" />
        <label>Password</label>
        <input type="password" v-model="password" />
        <div class="messages">
          <p class="success" v-if="success">Success!</p>
          <p class="error" v-if="error">Invalid Credentials</p>
        </div>

        <button>Login</button>
      </form>
    </section>
  </main>
</template>
<script>
import auth from "@/services/auth";

export default {
  name: "Login",
  data() {
    return { username: "", password: "", loginSuccess: false, error: false };
  },
  methods: {
    async submitCredentials() {
      await auth.login({ username: this.username, password: this.password });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";

main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

section {
  width: 30%;
  height: 24rem;
  background: white;
  border-radius: 6px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & form {
    width: 80%;
    background: white;

    & h1 {
      width: 100%;
      background: none;
      color: teal;
      display: flex;
      justify-content: center;
      align-items: center;

      & span {
        background: none;
        color: $secondaryColor;
        margin: 0 1rem;
      }
    }

    & h2 {
      background: white;
      font-family: $primaryFont;
    }

    & label {
      width: 100%;
      background: white;
      font-family: $primaryFont;
      font-size: 1rem;
    }

    & input {
      width: 100%;
      height: 2rem;
      outline: none;
      border: 1px solid gray;
      background: white;
      margin-bottom: 2rem;
      border-radius: 5px;
      font-family: $primaryFont;
      font-size: 1rem;
      padding: 0 0.5rem;
    }

    & button {
      height: 2rem;
      background: teal;
      border: none;
      outline: none;
      color: white;
      font-family: $primaryFont;
      font-size: 1rem;
      padding: 0.25rem 1rem;
      border-radius: 5px;
    }
  }
}

.messages {
  height: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $primaryFont;
  background: none;

  & p {
    background: none;

    &.success {
      color: green;
    }

    &.error {
      color: red;
    }
  }
}
</style>
