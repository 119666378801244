import axios from "axios";

let BASE_URL = process.env.BASE_URL;

const login = async (credentials) => {
  let res = await axios.post(`${BASE_URL}/auth/login`, credentials);

  return res.data.token;
};

export default { login };
